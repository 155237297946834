




































































































































































































import { AniversariantesListagem, Page, Pageable, Cliente, FiltroDeAniversariante } from "@/models"
import { primaryColor } from "@/plugins/vuetify"
import { aplicarMascaraParaCpfOculto, formatarCnpjOuCpf } from "@/shareds/formatadores"
import AlertModule from "@/store/vuex/aplicacao/AlertModule"
import { FindClienteUseCase, FindLojaUseCase } from "@/usecases"
import axios, { CancelTokenSource } from "axios"
import { Component, Vue } from "vue-property-decorator"
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { dateToPtBrFormat } from '@/shareds/date/date-utils'
import DialogoDeEdicaoDeCliente from '@/components/cliente/DialogoDeEdicaoDeCliente.vue'
import { Ref } from 'vue-property-decorator'
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'
import SeletorDeCliente from '@/views/application/clientes/SeletorDeCliente.vue'
import { backendDomain } from '@/shareds/utils'
import { serializarQueryString } from '@/common/request'
import CamposDeFiltrosDeAniversariantes from '@/views/application/clientes/CamposDeFiltrosDeAniversariantes.vue'
import { nextTick } from '@/shareds/utils'


@Component({
	components: {
		DialogoDeEdicaoDeCliente,
		SeletorDeLojasDoUsuario,
		SeletorDeCliente,
		CamposDeFiltrosDeAniversariantes,
	},
})
export default class TelaDeAniversariantes extends Vue {
	@Ref() dialogoDeEdicao!: DialogoDeEdicaoDeCliente
	@Ref() dialogoDeFiltros!: CamposDeFiltrosDeAniversariantes

	pagina: Page<AniversariantesListagem> | null = null
	paginado: Pageable = {
		page: 0,
		size: 10,
		sort: [],
	}

	totalRegistros = -1

	busca = ''
	primaryColor = primaryColor
	editando = true
	carregando = false
	lojaUnica = true
	periodo = 'dia'
	somenteComCashback = 'false'
	aniversariantes: AniversariantesListagem[] = []

	findUseCase = new FindClienteUseCase()
	findLojaUseCase = new FindLojaUseCase()

	cancelToken: CancelTokenSource | null = null

	clientes: Cliente[] = []

	filtro: FiltroDeAniversariante = {
		periodo: 'dia',
		cliente: null,
		lojaId: '',
		genero: '',
		profissao: null,
		somenteComCashback: false,
	}

	async created() {
		const idLojaUnica = await this.idLojaUnica()
		if(!idLojaUnica) return
		this.filtro.lojaId = idLojaUnica
		
		this.buscar()
	}

	async idLojaUnica() {
		const lojasIds = await this.findLojaUseCase.find()
		if(lojasIds.content.length === 1){
			return lojasIds.content[0].id
		}
		else { return null }
	}

	async buscar() {
		await nextTick()
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()
			const filtrosFormatados = {
				periodo: this.filtro.periodo || 'dia',
				cliente: this.filtro.cliente?.id || null,
				lojaId: this.filtro.lojaId || null,
				genero: this.filtro.genero || null,
				profissao: this.filtro.profissao?.codigo || null,
				somenteComCashback: this.filtro.somenteComCashback || 'false',
			}

			const params = {
				...filtrosFormatados,
				page: this.paginado.page || 0,
				size: this.paginado.size || 10,
			}

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			this.pagina = await this.findUseCase.findAniversariantes(params, axiosConfig)
			this.totalRegistros = this.pagina.totalElements
			this.aniversariantes = this.pagina.content
		} catch (error: any) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
			this.carregando = false
			this.dialogoDeFiltros.ocultar()
		}finally{
			this.carregando = false
			this.dialogoDeFiltros.ocultar()
		}
	}

	async pagePrevious() {
		this.paginado?.page ? (this.paginado.page -= 1) : (this.paginado.page = 0)
		await this.buscar()
	}

	async pageNext() {
		this.paginado = {
			...this.paginado,
			page: (this.paginado?.page || 0) + 1,
		}

		await this.buscar()
	}

	get clientesFormatados() {
		return this.aniversariantes.map((cliente) => ({
			...cliente,
			idade: this.calcularIdade(new Date(cliente.dataNascimento || '')),
			data: this.formatarData(cliente.dataNascimento || ''),
			telefone: cliente.telefone,
			cnpjOuCpfFormatado: cliente.cpfOuCnpj.length <= 11
				? aplicarMascaraParaCpfOculto(cliente.cpfOuCnpj)
				: formatarCnpjOuCpf(cliente.cpfOuCnpj),
		}))
	}

	formatarData(dataNascimento: string): string {
		const [, mes, dia] = dataNascimento.split('-')

		const meses: string[] = [
			'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
			'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro',
		]

		return `${dia} de ${meses[parseInt(mes) - 1]}`
	}

	calcularIdade(dataNascimento: Date): number {
		const hoje: Date = new Date();
		const anoNascimento: number = dataNascimento.getFullYear();
		const mesNascimento: number = dataNascimento.getMonth();
		const diaNascimento: number = dataNascimento.getDate();

		const anoAtual: number = hoje.getFullYear();
		const mesAtual: number = hoje.getMonth();
		const diaAtual: number = hoje.getDate();

		let idade: number = anoAtual - anoNascimento;

		if (mesAtual < mesNascimento || (mesAtual === mesNascimento && diaAtual < diaNascimento)) {
			idade--;
		}

		return idade;
	}

	atualizarCliente(cliente: Cliente) {
		if (!cliente) return

		const indice = this.clientes.findIndex(
			clienteExistente => clienteExistente.id === cliente.id,
		)
		indice === -1
			? this.clientes.push(cliente)
			: this.clientes.splice(indice, 1, cliente)
		
	}

	async abrirDialogoDeEditarCliente (idCliente: string ) {
		this.editando = true
		this.cancelToken = axios.CancelToken.source()

		const params = {
			gruposEconomicosId: UserLoginStore.usuario?.gruposEconomicos ? UserLoginStore.usuario?.gruposEconomicos.map(grupoEconomico => grupoEconomico.id) : null,
		}
		const axiosConfig = {
			cancelToken: this.cancelToken.token,
		}
		
		const cliente = await this.findUseCase.get(idCliente, params, axiosConfig)
		cliente.createdAt = dateToPtBrFormat(cliente.createdAt)
		this.dialogoDeEdicao.mostrar(cliente, false)
	}

	get urlDaBuscaAniversariantes() {
		if (!UserLoginStore.token) return ''
		const queryString = serializarQueryString({
			token: encodeURI(UserLoginStore.token),
			...this.filtro,
			cliente: this.filtro.cliente ? this.filtro.cliente.id : '',
			profissao: this.filtro.profissao ? this.filtro.profissao.codigo : '',
		})
		return `${backendDomain()}/clientes/relatorio-de-aniversariantes/Aniversariantes.csv?${queryString}`
	}

	abrirFiltros() {
		this.dialogoDeFiltros.mostrar()
	}

	aplicarFiltros() {
		this.paginado.page = 0
		this.buscar()
	}
}

